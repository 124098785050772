export type Config = {
  whitelabel: boolean;
  tenant: string;
  singleClaim?: boolean;
  useDemoIq?: boolean;
  useDemoIqFraud?: boolean;
  enableNpsSurvey?: boolean;
  enableMessaging?: boolean;
  enableIntercom?: boolean;
  enabledIntegrations?: 'policyLookup'[];
  enabledTabs?: string[];
  demoAuthentication?: boolean;
  authentication?: {
    domain: string;
    clientId: string;
    audience: string;
  };
  notifications?: {
    publicApiKey: string;
    feedId: string;
  };
  god?: boolean;
  hideUserManagement?: boolean;
  usePlatformMode?: boolean;
  useInvestigationMode?: boolean;
  useIntakeOnlyMode?: boolean;
  usePerspectiveViewInIntake?: boolean;
  usePerspectiveViewDemoInIntake?: boolean;
  useLocalizationDemoInIntake?: boolean;
  useTriageDemoInIntake?: boolean;
  intakeDisableAssignment?: boolean;
  investigationLinkMode?: boolean;
  investigationRepairCoordinationMode?: boolean;
};

let config = {
  whitelabel: false,
  tenant: 'Assured',
  singleClaim: false,
} as Config;

if (window.location.href.includes('platform=1')) {
  config = { ...config, usePlatformMode: true };
}
if (window.location.href.includes('perspective_view_beta=1')) {
  config = { ...config, usePerspectiveViewInIntake: true };
}
if (window.location.href.includes('perspective_view_beta_demo=1')) {
  config = {
    ...config,
    usePerspectiveViewInIntake: true,
    usePerspectiveViewDemoInIntake: true,
  };
}
if (window.location.href.includes('i18n=1')) {
  config = { ...config, useLocalizationDemoInIntake: true };
}
if (window.location.href.includes('triage=1')) {
  config = { ...config, useTriageDemoInIntake: true };
}
if (window.location.href.includes('link_investigation=1')) {
  config = { ...config, investigationLinkMode: true };
}
if (window.location.href.includes('link_repair_investigation=1')) {
  config = {
    ...config,
    useInvestigationMode: true,
    useIntakeOnlyMode: false,
    investigationLinkMode: true,
    investigationRepairCoordinationMode: true,
  };
}
if (window.location.href.includes('messaging=1')) {
  config = { ...config, enableMessaging: true };
}
if (process.env.REACT_APP_SETTINGS) {
  config = { ...config, ...JSON.parse(process.env.REACT_APP_SETTINGS) };
}

export default config;
