import { find, includes, indexOf, orderBy } from 'lodash';
import React from 'react';

import {
  ApolloClient, ApolloProvider, FieldPolicy, HttpLink, InMemoryCache, StoreObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';

import { CaseContact } from '../components/CaseView/types';
import config from '../config';

let previousToken: string;

export const AuthenticatedApolloProvider: React.FC = ({ children }) => {
  const authData = useAuth0();

  const getTokenSilently: () => Promise<string | undefined> = async () => {
    if (config.authentication) {
      try {
        const token = await authData.getAccessTokenSilently();
        return `user_${token}`;
      } catch (e: any) {
        // fall through
      }
    }

    const params = new URLSearchParams(window.location.search);
    const key = params.get('token');
    if (key) previousToken = key;
    return key || process.env.REACT_APP_API_KEY || previousToken;
  };

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_ENDPOINT,
    credentials: 'include',
  });

  const authLink = setContext(async (_, { headers }) => {
    if (headers?.authorization) {
      return { headers };
    }

    const token = await getTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  function makeListFieldPolicy(): FieldPolicy<any> {
    return {
      keyArgs: ['filter', 'sortInfo'],
      merge(existing = [], incoming, { args }) {
        if (args?.cursor) {
          return [...existing, ...incoming];
        } else {
          return [...incoming, ...existing.slice(incoming.length)];
        }
      },
    };
  }

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Case: {
          fields: {
            contacts: {
              read(contacts, options) {
                const isSortable = find(
                  options.field?.selectionSet?.selections,
                  { name: { kind: 'Name', value: 'reportState' } },
                );

                if (!isSortable) {
                  return contacts;
                }

                const sortFunc = (c: StoreObject) => {
                  const reportState: CaseContact['reportState'] =
                    options.readField('reportState', c) || {};

                  return [
                    reportState.submittedAt,
                    reportState.lastEngagedAt,
                    reportState.firstContactedAt,
                  ];
                };

                return orderBy(contacts, sortFunc, ['desc']);
              },
            },
          },
        },
        Query: {
          fields: {
            adminClaims: makeListFieldPolicy(),
            adminCases: makeListFieldPolicy(),
          },
        },
      },
    }),
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
