import moment from 'moment';

import { simulatorWaitForOperator as waitForOperator, useMessaging } from '../useMessaging';

interface Task {
  id: string;
  title: string;
}

interface EmmaSimulateArgs {
  tasks: Task[];
  setActiveTasks: (fn: (existing: Task[]) => Task[]) => void;
  setCompletedTasks: (fn: (existing: Task[]) => Task[]) => void;
  messaging: ReturnType<typeof useMessaging>;
}

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const sleepBeforeStartingEmma = () => sleep(1000);
export const sleepForOperatorMessageToReachEmma = () => sleep(1500);
export const sleepForEmmaToFinishMessage = () => sleep(6000);

export async function simulateEmma_shopAndPayment(args: EmmaSimulateArgs) {
  const rentalTask = args.tasks.find(t => t.id === 'rental_car');
  const paymentTask = args.tasks.find(t => t.id === 'payment_preference');

  args.messaging.showChat('chat_demoinsured');
  args.messaging.startEmma('chat_demoinsured');

  await sleepBeforeStartingEmma();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'emma',
    message:
      "Hi Dan, I'm Emma, an automated assistant helping your adjuster, Tom, process your claim. We have a few questions to help you to get you back on the road. Feel free to reply to me with complete sentences, and text STOP to opt-out of SMS messaging at any time.",
  });
  await sleepForEmmaToFinishMessage();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'emma',
    message:
      'Your policy qualifies you for a rental car while your blue Ford is being repaired. Enterprise is able to drop a vehicle off at your home, 2766 Emerson St, Palo Alto, CA 94306, this Thursday at 7 pm. Does that work? —Emma',
  });
  await waitForOperator();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'contact_insured',
    message:
      "Hey Emma, actually that doesn't work. Can you guys do this Friday at 10 am?",
  });

  await sleepForOperatorMessageToReachEmma();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'emma',
    message:
      "Yes, that's no problem Dan. Your rental drop-off is confirmed for this Friday at 10 am at your home, 2766 Emerson St, Palo Alto, CA 94306. —Emma",
  });

  await sleepForEmmaToFinishMessage();

  if (rentalTask) {
    args.setActiveTasks(existing =>
      existing.filter(t => t.id !== rentalTask.id),
    );
    args.setCompletedTasks(completedTasks =>
      completedTasks.concat([rentalTask]),
    );
  }

  await waitForOperator();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'contact_insured',
    message: 'Thanks Emma!',
  });

  await sleepForOperatorMessageToReachEmma();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'emma',
    message:
      'Our pleasure, Dan. Also, Dan, is your preferred payment method to be paid via check or direct deposit? —Emma',
  });

  await waitForOperator();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'contact_insured',
    message: "Check is fine if that's ok.",
  });

  await sleepForOperatorMessageToReachEmma();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'emma',
    message:
      'Of course, Dan. Should we send the check to 2766 Emerson St, Palo Alto, CA 94306, or is another address better? —Emma',
  });

  await waitForOperator();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'contact_insured',
    message: 'Actually, can you send it to 3 peter coutts circle?',
  });

  await sleepForOperatorMessageToReachEmma();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'emma',
    message:
      'Let me check, Dan. What city is 3 Peter Coutts Circle located in? —Emma',
  });

  await waitForOperator();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'contact_insured',
    message: 'Stanford, California',
  });

  await sleepForOperatorMessageToReachEmma();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'emma',
    message:
      "That's no problem Dan. Your check will be mailed to 3 Peter Coutts Circle, Stanford CA 94305. —Emma",
  });

  await sleepForEmmaToFinishMessage();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'emma',
    message:
      "Please let us know if there's anything else we can help with! —Emma",
  });

  await sleepForEmmaToFinishMessage();

  args.messaging.stopEmma('chat_demoinsured');

  if (paymentTask) {
    args.setActiveTasks(existing =>
      existing.filter(t => t.id !== paymentTask.id),
    );
    args.setCompletedTasks(completedTasks =>
      completedTasks.concat([paymentTask]),
    );
  }
}

export async function simulateEmma_blurryImages(args: EmmaSimulateArgs) {
  args.messaging.showChat('chat_demoinsured');
  args.messaging.startEmma('chat_demoinsured');

  await sleepBeforeStartingEmma();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'emma',
    message:
      "Hi Dan, we've been reviewing the images of the damage you provided us and it appears the image of the passenger side of the vehicle is blurry. Are you able to provide a clearer picture? If so, please click the link below to upload. —Emma",
  });

  await sleepForEmmaToFinishMessage();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'emma',
    message: 'Link sent to upload media',
    type: 'notice',
  });

  await waitForOperator();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'contact_insured',
    message: 'Sure Emma, let me check.',
  });

  await waitForOperator();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'emma',
    message: 'Demo: time advanced 24 hours',
    type: 'notice',
  });

  await waitForOperator();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'emma',
    message:
      'Hi Dan, just checking in on the images. Were you able to provide any additional photos? —Emma',
  });

  await waitForOperator();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'contact_insured',
    message: 'Oops! I totally forgot, let me get them now. 🙃',
  });

  await sleepForOperatorMessageToReachEmma();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'emma',
    message:
      'No worries Dan, we appreciate your help as we work to resolve your claim as soon as possible. 🙂 —Emma',
  });

  await waitForOperator();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'emma',
    message: 'Dan uploaded 1 photo to claim',
    type: 'notice',
  });

  await sleepForOperatorMessageToReachEmma();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'emma',
    message:
      "Thank you for uploading the photo Dan! We'll let you know if we need any more photos of the damage. —Emma",
  });

  await sleepForEmmaToFinishMessage();

  args.messaging.stopEmma('chat_demoinsured');
  args.setActiveTasks(existing =>
    existing.filter(t => !args.tasks.map(t => t.id).includes(t.id)),
  );
  args.setCompletedTasks(completedTasks => completedTasks.concat(args.tasks));

  await waitForOperator();

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'contact_insured',
    message: 'Emma, do you know when my car will be ready?',
  });

  await sleepForOperatorMessageToReachEmma();
  await sleep(1500);

  args.messaging.showEmmaRecommendation({
    chat: 'chat_demoinsured',
    label: 'Provide claim status update',
    message: `Hi Dan, your Ford Fusion is currently located at CARSTAR Chilton Auto Body San Carlos. Repairs are estimated to be completed on ${moment()
      .add(7, 'days')
      .format(/* Monday, October 10th */ 'dddd, MMMM Do')}. —Emma`,
  });
}

export async function simulateEmma_recommendationFlow(args: EmmaSimulateArgs) {
  args.messaging.showChat('chat_demoinsured');
  args.messaging.stopEmma('chat_demoinsured');

  args.messaging.simulateSendMessage({
    chat: 'chat_demoinsured',
    from: 'contact_insured',
    message: 'Emma, do you know when my car will be ready?',
  });

  await sleepForOperatorMessageToReachEmma();
  await sleep(1500);

  args.messaging.showEmmaRecommendation({
    chat: 'chat_demoinsured',
    label: 'Provide claim status update',
    message: `Hi Dan, your Ford Fusion is currently located at CARSTAR Chilton Auto Body San Carlos. Repairs are estimated to be completed on ${moment()
      .add(7, 'days')
      .format(/* Monday, October 10th */ 'dddd, MMMM Do')}. —Emma`,
  });
}
