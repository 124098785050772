import { useLocalStorage } from 'react-use';

interface Settings {
  godMode?: boolean;
}

export default function useLocalSettings() {
  const [settings, setSettings] = useLocalStorage<Settings>(
    'assured-settings',
    {},
  );

  return {
    settings,
    updateSettings: (newSettings: Partial<Settings>) => {
      setSettings(s => ({ ...s, ...newSettings }));
    },
  };
}
