import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { LightningBoltIcon } from '@heroicons/react/outline';

import config from '../../config';

export default function Header() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [value, setValue] = useState('');
  const { user, logout } = useAuth0();

  useEffect(() => {
    setValue(id);
  }, [id]);

  const onSubmit = () => {
    history.push(`/claim/${value}`);
  };

  return (
    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex">
        <h1 className="text-3xl font-bold leading-tight text-gray-900 flex-1 inline-flex items-center">
          <span>{config.tenant} ClaimView</span>
          {config.god ? (
            <span className="bg-indigo-500 text-white px-1 py-1 ml-4 rounded font-medium text-sm inline-flex items-center">
              <LightningBoltIcon className="w-4 h-4" />
            </span>
          ) : null}
        </h1>
        {!config.singleClaim ? (
          <form
            onSubmit={e => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <div className="flex rounded-md shadow-sm">
              <div className="relative flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg
                    className="h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  className="block h-full focus:outline-none border border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 w-40 rounded-none rounded-l-md pl-10 transition ease-in-out duration-150 sm:block sm:text-sm sm:leading-5"
                  placeholder="Enter Claim ID"
                  value={value}
                  onChange={e => setValue(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
              >
                Lookup
              </button>
            </div>
          </form>
        ) : null}
        {config.authentication && user ? (
          <div className="ml-4 flex items-center">
            <div className="">
              <div className="text-sm leading-none font-medium text-gray-700">
                {user.name}
              </div>
              <div className="text-xs leading-none mt-1 font-medium text-gray-400">
                {user.email}
              </div>
            </div>
            {user['https://assured.claims/role'] === 'administrator' ? (
              <Link
                to="/users"
                className="ml-4 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
              >
                Manage users
              </Link>
            ) : null}
            <button
              type="submit"
              className="ml-4 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
              onClick={() => logout()}
            >
              Log out
            </button>
          </div>
        ) : null}
      </div>
    </header>
  );
}
