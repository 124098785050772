import '@knocklabs/react-notification-feed/dist/index.css';

import classNames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import {
  AnnotationIcon, CheckCircleIcon, ClipboardListIcon, ClockIcon, PhotographIcon, ScaleIcon,
  SpeakerphoneIcon
} from '@heroicons/react/solid';
import { FeedItem } from '@knocklabs/client';
import {
  KnockFeedProvider, NotificationFeed, NotificationFeedPopover, NotificationIconButton, useKnockFeed
} from '@knocklabs/react-notification-feed';

import config from '../config';

const {
  formatTimestamp,
} = require('@knocklabs/react-notification-feed/dist/esm/utils');

export { NotificationFeed, NotificationFeedPopover, NotificationIconButton };

const TOKEN_CLAIM_PATH = 'https://assured.claims/knockToken';

const ICONS = {
  'claim-assigned': { icon: ClipboardListIcon, color: 'text-blue-500' },
  'new-comment-on-claim': { icon: AnnotationIcon, color: 'text-blue-500' },
  'new-comment-mention-on-claim': {
    icon: SpeakerphoneIcon,
    color: 'text-blue-500',
  },
  'case-investigation-started': {
    icon: ScaleIcon,
    color: 'text-blue-500',
  },
  'case-investigation-ready': {
    // Note: This is deprecated, we now use the other 2 notifications below.
    icon: CheckCircleIcon,
    color: 'text-blue-500',
  },
  'case-investigation-perspective-ready': {
    icon: CheckCircleIcon,
    color: 'text-blue-500',
  },
  'case-investigation-expired': {
    icon: CheckCircleIcon,
    color: 'text-blue-500',
  },
};

type BaseFeedProps = {
  onNotificationClick: (item: any) => any;
  renderItem: (d: {
    item: FeedItem;
    onItemClick?: (item: FeedItem) => void;
  }) => JSX.Element;
};

export const KnockNotificationFeed: React.FC<{
  displayMode: 'inline' | 'custom';
  inlineStyle?: React.CSSProperties;
  customRenderer?: (d: BaseFeedProps) => JSX.Element;
}> = ({ displayMode, inlineStyle, customRenderer }) => {
  const { user } = useAuth0();
  const history = useHistory();

  if (config.authentication && config.notifications && user) {
    const shared: BaseFeedProps = {
      onNotificationClick: item => {
        if (item.data.claim_id) {
          history.push(`/claims/${item.data.claim_id}`);
        } else if (item.data.case_id) {
          history.push(`/cases/${item.data.case_id}`);
        }
      },
      renderItem: ({ item, onItemClick }) => (
        <KnockFeedItem item={item} onItemClick={onItemClick} />
      ),
    };

    return (
      <>
        <KnockFeedProvider
          apiKey={config.notifications.publicApiKey}
          feedId={config.notifications.feedId}
          userId={user.sub}
          userToken={user[TOKEN_CLAIM_PATH]}
        >
          {displayMode === 'inline' ? (
            <div style={inlineStyle}>
              <NotificationFeed isVisible={true} {...shared} />
            </div>
          ) : displayMode === 'custom' && customRenderer ? (
            customRenderer(shared)
          ) : null}
        </KnockFeedProvider>
        <style>{`
          a[href^="https://knock.app"] { display: none; }

          .KnockFeedItem__Block blockquote {
            margin: 0.25rem 0;
            border-left: 2px solid #9fa6b2;
            padding: 0.1rem 0.5rem;
            pointer-events: none;
            color: #6b7280;
          }

          .KnockFeedItem__Block strong {
            white-space: nowrap;
          }

          .rnf-dropdown {
            display: none;
          }

          .rnf-notification-feed-popover {
            box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
            border-radius: .5rem;
            overflow: hidden;
          }

          .rnf-notification-feed-popover__arrow {
            display: none;
          }
        `}</style>
      </>
    );
  }

  return <></>;
};

const KnockFeedItem: React.FC<{
  item: FeedItem;
  onItemClick?: (item: FeedItem) => void;
}> = ({ item, onItemClick }) => {
  const { feedClient } = useKnockFeed();
  const type = item.source.key;
  const iconData = type in ICONS ? ICONS[type as keyof typeof ICONS] : null;

  // Hide archived items
  if ((item as any).archived_at) {
    return null;
  }

  return (
    <div
      key={item.id}
      className="group flex items-center cursor-pointer border-b border-gray-200 hover:bg-gray-50"
      onClick={() => onItemClick?.(item)}
    >
      <div className="px-6 py-4 text-sm flex-1 inline-flex items-center">
        <div className="mr-4">
          {iconData ? (
            <iconData.icon className={classNames('h-5 w-5', iconData.color)} />
          ) : null}
        </div>
        <div>
          {item.blocks.map(block => (
            <div
              className="text-gray-700 KnockFeedItem__Block"
              dangerouslySetInnerHTML={{ __html: block.rendered }}
            />
          ))}
          <div className="text-sm text-gray-400">
            {formatTimestamp(item.inserted_at)}
          </div>
        </div>
      </div>
      <div className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <a
          href="#"
          className="text-blue-600 hover:text-blue-800 text-xl"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            feedClient.markAsArchived(item);
          }}
        >
          ×
        </a>
      </div>
    </div>
  );
};
