import './App.css';
import 'react-image-lightbox/style.css';

import { H } from 'highlight.run';
import { createBrowserHistory } from 'history';
import React, { Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Link, Redirect, Route, RouteComponentProps, RouteProps, Router, useRouteMatch
} from 'react-router-dom';

import PageLoader from '@assured/frontend/src/components/PageLoader';
import { Auth0Provider, useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import Header from './components/shared/Header';
import Layout from './components/shared/Layout';
import config from './config';
import { EmmaProvider } from './hooks/useEmma';
import { MessagingProvider } from './hooks/useMessaging';
import { AuthenticatedApolloProvider } from './services/apollo';

const MessagingContainer = React.lazy(
  () => import('./components/Messaging/MessagingContainer'),
);

const Welcome = React.lazy(() => import('./components/Welcome'));
const UserManagement = React.lazy(() => import('./components/UserManagement'));
const Settings = React.lazy(() => import('./components/Settings'));
const IntegrationTestBench = React.lazy(
  () => import('./components/IntegrationTestBench'),
);

const ClaimDashboard = React.lazy(() => import('./components/ClaimDashboard'));
const ClaimView = React.lazy(() => import('./components/ClaimView'));

const CaseDashboard = React.lazy(() => import('./components/CaseDashboard'));
const CaseView = React.lazy(() => import('./components/CaseView'));

const PluginDashboard = React.lazy(
  () => import('./components/PluginDashboard'),
);

const CatMap = React.lazy(() => import('./components/Cat/CatMap'));
const CatFlow = React.lazy(() => import('./components/Cat/CatFlow'));
const CatManagement = React.lazy(
  () => import('./components/Cat/CatManagement'),
);
const CaseViewStandaloneRenderer = React.lazy(
  () => import('./components/CaseViewStandaloneRenderer'),
);
const SidekickDashboard = React.lazy(
  () => import('./components/Sidekick/SidekickDashboard'),
);
const FlowBuilder = React.lazy(() => import('./components/FlowBuilder'));

const history = createBrowserHistory();

const Placeholder: React.FC<{ message: JSX.Element | string }> = ({
  message,
}) => (
  <div className="absolute inset-0 flex items-center justify-center">
    <div className="font-bold text-gray-500 max-w-xl p-4 text-center">
      {message}
    </div>
  </div>
);

const ProtectedRoute = ({ component, ...args }: RouteProps) =>
  component ? (
    config.authentication ? (
      <Route
        component={withAuthenticationRequired(
          component as any, // due to auth0 typings not being compatible with react-router-dom
          {
            onRedirecting: () => (
              <Placeholder message="Authorizing access to Assured ClaimView..." />
            ),
          },
        )}
        {...args}
      />
    ) : (
      <Route component={component} {...args} />
    )
  ) : null;

const onRedirectCallback = (appState: any) => {
  history.replace(appState?.returnTo || window.location.pathname);
};

function Main() {
  const { error, logout, user } = useAuth0();

  useEffect(() => {
    const Intercom = (window as any).Intercom;
    if (config.enableIntercom && user) {
      Intercom('boot', {
        app_id: 's49bfo9k',
        user_id: user.sub,
        name: user.name,
        email: user.email,
        company: {
          id: config.tenant,
          name: config.tenant,
        },
      });

      const unregister = history.listen(() => {
        Intercom('update');
      });

      return () => unregister();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      analytics.identify(user.sub, {
        email: user.email,
        name: user.name,
        company: config.tenant,
      });
      H.identify(user.sub, {
        highlightDisplayName: user.name || user.email,
        email: user.email,
        name: user.name,
        company: config.tenant,
      });
    }
  }, [user]);

  if (error) {
    return (
      <Placeholder
        message={
          <div>
            <div>
              {(error as any)?.error_description ||
                'An unknown authentication error occurred. Please try again.'}
            </div>
            <div className="mt-1">
              <button
                className="cursor-pointer text-blue-700 hover:text-blue-600 text-xs font-bold"
                onClick={() => logout()}
              >
                Log out
              </button>
            </div>
          </div>
        }
      />
    );
  }

  return (
    <AuthenticatedApolloProvider>
      <MessagingProvider>
        <EmmaProvider>
          <Router history={history}>
            <Helmet>
              <title>Assured ClaimView</title>
            </Helmet>
            <div className="App bg-gray-100">
              <Suspense
                fallback={
                  config.usePlatformMode ? (
                    <Layout navigation={[]}>
                      <PageLoader
                        style={{ minHeight: '80rvh' }}
                        text="Loading Assured..."
                      />
                    </Layout>
                  ) : (
                    <>
                      <Header />
                      <PageLoader
                        style={{ minHeight: '80rvh' }}
                        text="Retrieving Assured ClaimView..."
                      />
                    </>
                  )
                }
              >
                <ProtectedRoute exact path="/" component={Welcome} />
                <ProtectedRoute exact path="/cases" component={CaseDashboard} />
                <ProtectedRoute
                  exact
                  path="/claims"
                  component={ClaimDashboard}
                />

                <ProtectedRoute path="/plugins" component={PluginDashboard} />

                <ProtectedRoute path="/users" component={UserManagement} />
                <ProtectedRoute path="/settings" exact component={Settings} />

                <Route
                  exact
                  path="/claims/:id"
                  render={props => (
                    <Redirect to={`/claims/${props.match.params.id}/general`} />
                  )}
                />
                <ProtectedRoute
                  path="/claims/:id/:view?"
                  component={ClaimView}
                />
                <Route
                  path="/claim/"
                  render={props => (
                    <Redirect
                      to={`/claims/${props.location.pathname.substring(
                        '/claim/'.length,
                      )}${props.location.search}`}
                    />
                  )}
                />

                <ProtectedRoute path="/cases/:id" component={CaseView} />

                {/* CAT Administrator */}
                <Route exact path="/cat">
                  <Redirect to="/cat/dashboard" />
                </Route>
                <Route path="/cat/dashboard">
                  <Layout navigation={[{ current: true, name: 'CAT' }]}>
                    <CatMap />
                  </Layout>
                </Route>
                <ProtectedRoute
                  path="/cat/management"
                  component={CatManagement}
                />
                <Route path="/cat/configurator">
                  <Layout
                    navigation={[
                      { href: '/cat', name: 'CAT' },
                      { current: true, name: 'Configurator' },
                    ]}
                  >
                    <CatFlow />
                  </Layout>
                </Route>

                {/* Sidekick */}
                <ProtectedRoute
                  path="/sidekick/:claimId?/:workflowId?"
                  component={SidekickDashboard}
                />

                {/* Flow Builder */}
                <Route path="/settings/flow_builder">
                  <Layout
                    navigation={[
                      { href: '/settings', name: 'Settings' },
                      { current: true, name: 'Flow Builder' },
                    ]}
                  >
                    <FlowBuilder />
                  </Layout>
                </Route>

                {/* Integration testing */}
                <ProtectedRoute
                  path="/_integration_test_bench"
                  component={IntegrationTestBench}
                />

                {/* Renderer for CaseView used for PDF generation */}
                <Route
                  path="/_caseview_renderer"
                  component={CaseViewStandaloneRenderer}
                />

                {config.enableMessaging ? <MessagingContainer /> : null}
              </Suspense>
            </div>
          </Router>
        </EmmaProvider>
      </MessagingProvider>
    </AuthenticatedApolloProvider>
  );
}

function App() {
  // Skip Auth provider for specifically the renderer since in development,
  // it is loaded in Docker, with a non-localhost non-HTTPS docker.internal
  // URL, which causes auth0-spa to panic about insecure origin.
  const isNoAuthPath = /^\/_caseview_renderer/.test(window.location.pathname);

  if (config.authentication && !isNoAuthPath) {
    return (
      <Auth0Provider
        domain={config.authentication.domain}
        clientId={config.authentication.clientId}
        audience={config.authentication.audience}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        <Main />
      </Auth0Provider>
    );
  } else {
    return <Main />;
  }
}

export default App;
