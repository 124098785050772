import React, { useContext, useEffect, useState } from 'react';
import config from '~/src/config';

import { useMessaging } from '../useMessaging';
import {
  simulateEmma_blurryImages, simulateEmma_recommendationFlow, simulateEmma_shopAndPayment
} from './simulator';

export enum EmmaSidebarState {
  TASK_LIST,
  ADD_NEW_TASKS,
  ADD_NEW_SMART_TASK_FROM_CLAIM,
}

interface Task {
  id: string;
  title: string;
}

interface IEmmaState {
  completedTasks: Task[];
  activeTasks: Task[];
  sidebarState: EmmaSidebarState;

  addingSmartTask: Task | null;
}

interface IEmmaContext {
  state: IEmmaState;
  setState: React.Dispatch<React.SetStateAction<IEmmaState>>;
}

const EmmaState: IEmmaState = {
  completedTasks: [],
  activeTasks: [],
  sidebarState: EmmaSidebarState.TASK_LIST,

  addingSmartTask: null,
};

const EmmaContext = React.createContext({
  state: EmmaState,
  setState: (() => {}) as React.Dispatch<React.SetStateAction<IMessageState>>,
});

export const EmmaProvider: React.FC<{}> = ({ children }) => {
  const [_, setUpdatedAt] = useState(null);
  const [messagingState, setMessagingState] = useState(EmmaState);
  return (
    <EmmaContext.Provider
      value={{
        state: messagingState,
        setState: s => {
          setMessagingState(s);
          setUpdatedAt(Date.now());
        },
      }}
    >
      {children}
    </EmmaContext.Provider>
  );
};

export function useEmma() {
  const messaging = useMessaging();
  const ctx = useContext<IEmmaContext>(EmmaContext);
  const { state, setState } = ctx;

  const setActiveTasks = (fn: (existing: Task[]) => Task[]) => {
    setState(s => ({
      ...s,
      activeTasks: fn(s.activeTasks),
    }));
  };
  const setCompletedTasks = (fn: (existing: Task[]) => Task[]) => {
    setState(s => ({
      ...s,
      completedTasks: fn(s.completedTasks),
    }));
  };

  const generateSimulatePayload = (tasks: Task[]) => {
    return {
      tasks,
      messaging,
      setActiveTasks,
      setCompletedTasks,
    };
  };

  return {
    enabled: !!config.enableMessaging,

    completedTasks: state.completedTasks,
    activeTasks: state.activeTasks,
    sidebarState: state.sidebarState,
    addingSmartTask: state.addingSmartTask,

    assignTasksToEmma: (tasks: Task[]) => {
      setState(s => ({
        ...s,
        activeTasks: [...s.activeTasks, ...tasks],
        sidebarState: EmmaSidebarState.TASK_LIST,
      }));

      if (tasks.some(t => t.id === 'rental_car')) {
        simulateEmma_shopAndPayment(generateSimulatePayload(tasks));
      } else if (tasks.some(t => t.id === 'additional_images')) {
        simulateEmma_blurryImages(generateSimulatePayload(tasks));
      } else if (tasks.some(t => t.id === 'canvas')) {
        simulateEmma_recommendationFlow(generateSimulatePayload(tasks));
      }
    },

    setSidebarState: (sidebarState: EmmaSidebarState) => {
      setState(s => ({
        ...s,
        sidebarState,
      }));
    },

    cancelTask: (taskId: string) => {
      setActiveTasks(tasks => tasks.filter(t => t.id !== taskId));
    },

    openSmartTask: (task: Task) => {
      setState(s => ({
        ...s,
        sidebarState: EmmaSidebarState.ADD_NEW_SMART_TASK_FROM_CLAIM,
        addingSmartTask: task,
      }));
    },
  };
}
